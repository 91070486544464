import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import '../styles/studio.css'
import Layout from "../components/layout"
import man from "../images/man.png"
import man2 from "../images/man2.png"


const IndexPage = () => {
    return (
        <Layout>
                 <div id="container-title">

<div id="wrapper-conteudo">
   <div  id="conteudo-100-col">
      <p data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="titulo-texto gt-light">We are your new digital partner 🎉</p>
      <h2 data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="texto gt-light">At Forty Degrees, we work as a multidisciplinary team prepared to guarantee your needs with a costumize solution.<br></br> Together, we will grow your business and increase your digital presence for the whole world, always with the eyes in the future.<br></br> We create value that build connection with your audience.</h2>
   </div>
</div>
</div>
<div id="wrapper-elements">
<div id="conteudo-50-col" class="col col-50">
      <img class="img-element" src={man}  alt="forty degrees" />
      <p class="gt-light titulo-project">Nuno Coelho</p>
      <p class="gt-light ano-project">Co-founder & Creative Director</p>
   </div>
   <div id="conteudo-50-col" class="col col-50">
      <img class="img-element" src={man2}  alt="forty degrees" />
      <p class="gt-light titulo-project">Tiago Mendes</p>
      <p class="gt-light ano-project">Co-founder & CTO</p>
   </div>
   </div>
<div id="wrapper-positions">
<div class="wrapper-about">
   <p class="gt-light titulo">We believe in digital</p>
   <p class="gt-light texto">If you are a web developer or product designer, you like challenges and you see yourself in our project development approach, you can see our open positions to start working with us. Determination and attitude are part of success.</p>
   <Link to="/positions" style={{ textDecoration: 'none' }}><button class="btn-next gt-regular white ">See our open positions!</button></Link>

</div>
</div>
        </Layout>

    )
}



export default IndexPage
